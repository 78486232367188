.modalButtonDisabled{
    display: none;
}
.modalButton{
    position: fixed;
    right: 3.5%;
    top: 190px;
    z-index: 10;
    padding: 1em;
    border: 1px solid black;
  
}
.buttonNumber{
    position: absolute;
    right: -5px;
    bottom: -5px;
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 50%;
    padding: 4px;
    font-weight: bold;
}