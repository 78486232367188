.reviews{
    padding: 30px 0 30px 0;
   text-align: center;
    
}
.reviews-title{
    margin-bottom: 70px;
}
.reviews-text{
    margin-bottom: 70px;

}
.reviews-img{
    max-width: 290px;
margin: 0 auto 40px auto;
}
.reviews-button{  display: block;
    margin: 90px auto 90px auto;
    width: 300px;
    height: 50px;
    background-color: #f5c231;
    font-size: 26px;
    font-weight: bold;}
    @media only screen and (min-width: 1020px) {
        .reviews-img{
            max-width: 400px;
        }
    }