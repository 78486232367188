.contacts{ padding: 70px 0 70px 0;
    text-align: center;
  background-color: #f0f0f0;}
    .contacts-title{
        margin-bottom: 70px;
    }
  .contacts-list{
    margin-top: 70px;
    gap: 15px;
    display: inline-block;
    text-align: start;
    margin-left: auto;
    margin-right: auto;
  }
 .social-networks-list{
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-top: 70px;
 }
 .contacts-itemText{
  vertical-align: text-bottom;
 }
.agreement-link{
  display: inline-block;
  margin-top:40px ;
  font-size: 11px;
  text-decoration: underline;
}